import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Container, Col, Row } from 'react-bootstrap';
import Hero from '../../components/Hero';
import waterImage from '../../assets/images/misc2.jpg';
import natureImage from '../../assets/images/nature.jpg';
import humansImage from '../../assets/images/misc1.jpg';
import animalsImage from '../../assets/images/animals.jpg';
import dailyImage from '../../assets/images/sunrise.jpg';
import Footer from '../../components/Footer';
import './Home.css';

const Home = () => {
  return (
    <>
      <div className="Home pb-5">

        <Container>
          <Hero />
          <Row className="Home-categories m-auto">
            <Col className="my-3" xs="6" md="3">
              <Card className="Home-category">
                <LinkContainer to="/playlist/water">
                  <Card.Img className="h-100" variant="top" src={waterImage} />
                </LinkContainer>
              </Card>
            </Col>

            <Col className="my-3" xs="6" md="3">
              <Card className="Home-category">
                <LinkContainer to="/playlist/nature">
                  <Card.Img className="h-100" variant="top" src={natureImage} />
                </LinkContainer>
              </Card>
            </Col>

            <Col className="my-3" xs="6" md="3">
              <Card className="Home-category">
                <LinkContainer to="/playlist/humans">
                  <Card.Img className="h-100" variant="top" src={humansImage} />
                </LinkContainer>
              </Card>
            </Col>

            <Col className="my-3" xs="6" md="3">
              <Card className="Home-category">
                <LinkContainer to="/playlist/animals">
                  <Card.Img className="h-100" variant="top" src={animalsImage} />
                </LinkContainer>
              </Card>
            </Col>

            <Col className="my-3" xs="12">
              <Card className="Home-category">
                <LinkContainer to="/playlist/daily">
                  <Card.Img className="h-100" variant="top" src={dailyImage} />
                </LinkContainer>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>

      <Footer />
    </>
  );
};

export default Home;