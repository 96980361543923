import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import './Hero.css';

const Hero = () => {
  return (
    <Jumbotron className="Hero mb-0 pt-5 pb-2">
      <h1 className="Hero-heading text-center text-light pb-2">
        <span className="Hero-heading--small">Welcome to{' '}</span>
              Visions in Words
            </h1>
      <hr className="Hero-hr" />
      <p className="text-center text-white-50">Explore the links below to listen to the spoken word poetry of Jake Hurt Hughes.</p>
      <p className="text-center text-white-50">Go ahead, kick back, close your eyes and listen a while.</p>
      <hr className="Hero-hr" />
    </Jumbotron>
  );
};

export default Hero;