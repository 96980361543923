const headers = {
  'Accept': 'application/json',
  'Content-Type': 'applicaton/json'
};

export async function getTracks(params) {
  const config = {
    headers
  };

  try {
    const response = await fetch(`/api/poems/category/${params.category}`, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log(error);
    return [];
  }
}
